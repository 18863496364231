<template>
  <div class="forgotPswdContainer">
    <el-button
      icon="el-icon-arrow-left"
      plain
      text
      class="backBtn"
      @click="handleBackBtnClick"
      >{{ __("Back to Login") }}</el-button
    >

    <div class="forgot-pswd-title">
      <div>
        {{ resetText }}
      </div>
      <div class="welcome-dot"><span>.</span></div>
    </div>
    <div class="forgot-info">
      <span v-if="!isTwoFactorAuthentication">
        <!-- eslint-disable-next-line -->
        {{ __("If you have forgotten your password, fill in your email address below and we will send you a new password via our Secure Password Reset facility.") }}
      </span>
      <span v-else>
        <!-- eslint-disable-next-line -->
        {{ __("If you have lost your 2FA credentials, fill in your email address below and we will send you an email to reset your password and 2FA") }}
      </span>
    </div>
    <el-form
      ref="forgotPswdForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="credentials"
      hide-required-asterisk
      @submit.native.prevent
    >
      <el-form-item
        class="email-form-item text"
        prop="email"
        :label="__('Email address')"
      >
        <el-input
          id="email"
          name="email"
          v-model="credentials.email"
          @keyup.enter.native="submitForm"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          id="sign-in-btn"
          class="w-full text"
          type="primary"
          :loading="isSubmitting"
          @click="submitForm"
        >
          {{ resetText }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validEmail } from "@/utils/validate";
import { mapActions } from "vuex";

export default {
  name: "ForgotPasswordForm",
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error(__("Please enter valid email")));
      } else {
        callback();
      }
    };
    return {
      credentials: {
        email: "",
        for_two_fa_reset: false
      },
      rules: {
        email: [
          { required: true, message: __("Email field required") },
          { validator: validateEmail, trigger: "blur" }
        ]
      },
      isSubmitting: false,
      redirectFrom: ""
    };
  },
  async mounted() {
    this.redirectFrom = this.$route.params.redirectFrom;
  },
  methods: {
    ...mapActions("users", {
      sendForgotPasswordEmail: "sendForgotPasswordEmail"
    }),
    handleBackBtnClick() {
      this.$router.push({ path: "/login" }).catch(() => {});
    },
    submitForm() {
      let self = this;
      this.$refs.forgotPswdForm.validate(valid => {
        if (valid) {
          self.isSubmitting = true;
          self.credentials.for_two_fa_reset = self.isTwoFactorAuthentication;
          self
            .sendForgotPasswordEmail(self.credentials)
            .then(data => {
              // console.log("Forgot mail:", data);
              self.isSubmitting = false;
              self.$message({
                type: "success",
                message: data.message
              });
            })
            .catch(data => {
              self.isSubmitting = false;
              self.$message({
                type: "error",
                message: data.message
              });
            });
        }
      });
    }
  },
  computed: {
    /**
     * check redirect is 2 factor auth
     * @returns {boolean}
     */
    isTwoFactorAuthentication() {
      return this.redirectFrom === "2FA";
    },
    /**
     * reset password text
     * @returns {string}
     */
    resetText() {
      return this.isTwoFactorAuthentication
        ? __("Reset Password and 2FA")
        : __("Reset Password");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.forgotPswdContainer {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .backBtn {
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding-left: 0px;

    ::v-deep i {
      font-size: 18px;
      font-weight: bolder;
    }

    ::v-deep span {
      margin-bottom: 6px;
    }
  }

  .forgot-pswd-title {
    font-weight: bolder;
    font-size: 34px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 20px;
    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 7px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
  }

  .email-form-item {
    margin-bottom: 60px;
  }

  #sign-in-btn {
    border-radius: 5px;
    height: 50px;
  }

  .forgot-info {
    margin-bottom: 40px;
  }
}

.w-full {
  width: 100%;
}
</style>
